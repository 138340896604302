<template>
  <HomePage
    class="main-content"
    :swipe-img-list="swipeImgList"
    :app-code="APP_CODE"
    :api-hosts="API_HOSTS"
    :button-before-style="buttonBeforeStyle"
    :button-after-style="buttonAfterStyle"
    :download-recommend-tag-img="downloadRecommendTagImg"
    :logo-icon="logoIcon"
    :logo-img="logoImg"
  />
</template>

<script>
import HomePage from '@/components/common/home-page/index.vue'
import logoImg from './assets/images/logo.png'
import downloadRecommendTagImg from '@/assets/images/hot_tag_recommend_new.png'
import logoIcon from './assets/images/logo_icon.png'

import { APP_CODE, API_HOSTS } from '../config'
import buttonImg from './assets/images/icon_download.png'

export default {
  components: {
    HomePage,
  },
  data() {
    return {
      logoImg,
      logoIcon,
      downloadRecommendTagImg,
      swipeImgList: [
        { bg: require('./assets/images/lu_bg_1.png') },
        { bg: require('./assets/images/lu_bg_2.png') },
        { bg: require('./assets/images/lu_bg_3.png') },
        { bg: require('./assets/images/lu_bg_4.png') },
      ],
      APP_CODE,
      API_HOSTS,
      buttonBeforeStyle: {
        background: `url(${buttonImg}) no-repeat center`,
        backgroundSize: 'cover',
        width: '14px',
        height: '14px',
        marginRight: '4px',
      },
      buttonAfterStyle: {
        background: `url(${buttonImg}) no-repeat center`,
        backgroundSize: 'cover',
        width: '14px',
        height: '14px',
        marginLeft: '4px',
      },
    }
  },
}
</script>

<style>
@import url('../../../assets/css/minireset.min.css');
@import url('../../../assets/css/common.css');
@import url('../common.css');
</style>

<style scoped>
.main-content ::v-deep .home__logo {
  width: fit-content;
  margin-left: 20px;
}
</style>
